<template>
  <div class="flex h-full">
    <loader :loading="loading" :backdrop="true"/>
    <div class="side-panel">
      <DetailsSidePanel>
        <template v-slot:heading>shared profile data block details</template>
        <template v-slot:text>
          <span v-if="isClone">
            add a new version of the authorized data block {{name}} for the current profile
          </span>
          <span v-else>
            add a new authorized data block to the profile based on the existing data block template and fill data record values.
          </span>
        </template>
      </DetailsSidePanel>
    </div>
    <div class="w-2/3">
      <div v-if="!isClone" class="mb-8 flex flex-col">
        <button @click="clearTemplate" type="button" class="btn-primary btn-transparent self-end">clear all</button>
        <div>
          <label class="label">template</label>
        </div>
        <div>
          <Dropdown
            :options="templates"
            :value="currentTemplate"
            placeholder="select template"
            @input="setTemplate"
            objectMode
          />
        </div>
      </div>
      <shared-profile-data-form
        :initial-values="initialValues"
        :on-submit="handleSubmit"
        :isClone="isClone"
        @close="() => redirectTo(basePath, {profileId})"
      />
    </div>
  </div>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Dropdown from '@/components/ui/Dropdown';
  import Loader from '@/components/ui/Loader';
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import SharedProfileDataForm from "./SharedProfileDataForm";

  export default {
    name: "SharedProfileDataCreate",
    components: {
      Loader,
      SharedProfileDataForm,
      DetailsSidePanel,
      Dropdown,
    },
    mixins: [
      ModalNavigation,
      NotifyMixin,
    ],
    computed: {
      profileId() {
        return this.$route.params.profileId;
      },
      name() {
        return this.$route.params.name;
      },
      version() {
        return this.$route.params.version;
      },
      isClone() {
        return Boolean(this.name && this.version);
      },
      basePath() {
        return this.$route.name.replace(`dataBlocks.${this.isClone ? 'clone' : 'create'}`, 'details');
      },
      basePathEdit() {
        return this.$route.name.replace('clone', 'edit');
      },
    },
    data(){
      return {
        initialValues: {},
        currentTemplate: null,
        templates: [],
        loading: false,
      }
    },
    watch: {
      currentTemplate: {
        handler: function (value) {
          if (value) {
            const { name, description, keys } = value;
            this.initialValues = {
              name,
              description,
              records: keys.map(({ id: keyId, key, specificationId }) => ({ keyId, key, specificationId })),
            };
          }
        },
        immediate: true
      },
    },
    methods: {
      handleSubmit({ name, description, records }) {
        if (!this.loading) {
          this.loading = true;

          return this.$raaDataProvider.create('profileDataBlocks', {
            data: [{ name, description, records }],
            profileId: this.profileId,
          })
            .then(() => {
              this.redirectTo(this.basePath, {profileId: this.profileId});

              this.notifySuccess(`Data block ${name} has been created`);
            })
            .catch(error => this.notifyError(error.message))
            .finally(() => this.loading = false);
        }
      },
      redirectTo(pathName, params = {}) {
        this.$router.replace({
          name: pathName,
          params: {direction: 'back', ...params}
        });
      },
      fetchSharedDataBlock() {
        this.loading = true;
        const { profileId, name, version } = this;
        this.$raaDataProvider.get('profileDataBlockVersioned', { profileId, name, version })
          .then(result => {
            this.initialValues = result;
          })
          .catch(error => {
            this.redirectTo(this.basePathEdit, this.$route.params);
            this.notifyError(error.message);
          })
          .finally(() => this.loading = false);
      },
      fetchTemplates() {
        this.$raaDataProvider.getList('profileTemplates', {size: 999})
          .then(({ content }) => {
            this.templates = content.filter(item => !item.deletedAt).map(({id, name, description, keys}) => ({
              key: id,
              value: name.toLocaleLowerCase(),
              name: name.toLocaleLowerCase(),
              description,
              keys,
            }));
          })
          .catch((err) => this.notifyError(err.message));
      },
      setTemplate(value) {
        if (value) {
          this.currentTemplate = value;
        }
      },
      clearTemplate() {
        this.initialValues = {};
        this.currentTemplate = null;
      },
    },
    mounted() {
      if (this.isClone) {
        this.fetchSharedDataBlock();
        this.setActiveModalTitle(`duplicate data block ${this.name}`);
      } else {
        this.fetchTemplates();
      }
    },
  }
</script>

<style scoped>
  .label {
    @apply text-black text-sm font-600 font-inter block select-none tracking-wide lowercase;
    margin-bottom: 8px;
  }
</style>
